.wrapper {

	min-height:100vh;

	&::before {
		width: 100%;
		height: 100%;
		top: 0;
		position: fixed;
		z-index: -1;
		background-position: center;
		background-size: cover;
		animation-fill-mode: both;
		content: '';
		background-image: url('./images/about-julian.jpg');
		background-image: -webkit-image-set( url(./images/about-julian.jpg) 1x, url(./images/about-julian@2x.jpg) 2x);
		opacity: .5;
	}
}

.quote {
	display: block;
	width: 85%;
	color: #fff;
	padding-top: 5em;
	padding-bottom: 1em;
}

.hero {
	display: block;
	vertical-align: middle;
	padding-top: 20%;
	@media (min-width: 48em) {
		padding-top: 15%;
	}
}
