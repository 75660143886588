@font-face {
  font-family: 'PitchRegular';
  src: url('../fonts/PitchWeb-Regular.eot');
  src: url('../fonts/PitchWeb-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PitchWeb-Regular.woff2') format('woff2'),
       url('../fonts/PitchWeb-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'PitchBold';
  src: url('../fonts/PitchWeb-Bold.eot');
  src: url('../fonts/PitchWeb-Bold.eot?#iefix') format('embedded-opentype'),
       url('../fonts/PitchWeb-Bold.woff2') format('woff2'),
       url('../fonts/PitchWeb-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
