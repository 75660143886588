/* ---------------------------------------------------------------------
base
------------------------------------------------------------------------ */

body {
  background-color: rgb(57, 61, 66);
  background-size: cover;
  font-family: 'PitchRegular', Courier, monospace;
  color: #fff;
}

a {
  text-decoration: none;
  color: inherit;
  position: relative;
  padding-bottom:.17em;
  cursor: pointer;
}

a:visited {
  text-decoration: none;
  color: inherit;
  position: relative;
  padding-bottom:.17em;
}

a:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: red;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: all 0.15s ease-out .1s;
  transition: all 0.15s ease-out .1s;
}

a:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

@-ms-viewport{
  width: device-width;
}

picture {
  width: 100%;
}

.flush-margin {
  margin: 0;
}

.flush-padding {
	padding-left:0; padding-right:0;
}

/* ---------------------------------------------------------------------
typography
------------------------------------------------------------------------ */

h1, h2, li, p {
  font-size: 1em;
  line-height: 1.85em;
  color: #fff;
  padding-bottom: 1.25em;
}

h3 {
  font-size: 1em;
  line-height: 1.85em;
  color: #fff;
}

em {
  font-family: 'PitchBold', Courier, monospace;
}

.gray {
  font-family: inherit;
  color: #73767b;
}

.strike {
  text-decoration: line-through;
}
