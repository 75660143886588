:global {

	.full-width {
	  width: 100%;
	  max-width: none;
	}

	.img-portfolio {
	  width: 100%;
	  height: auto;
	  object-fit: contain;
	}

	.img-1010 {
	  max-width: 100%;
	  height: auto;
	}

	.img-875 {
	  max-width: 100%;
	  height: auto;
	}

	.img-560 {
	  max-width: 100%;
	  height: auto;
	}

	.quote {
	  display: block;
	  width: 85%;
	  color: #fff;
	  padding-top: 5em;
	  padding-bottom: 1em;
	}

	/* ---------------------------------------------------------------------
	rhythm
	------------------------------------------------------------------------ */

	.vr {
	  padding-bottom: 4em;
	}
	  @media (min-width: 48em) {
	      .vr {
	        padding-bottom: 8em;
	      }
	  }
	  @media (min-width: 64em) {
	      .vr {
	        padding-bottom: 12.5em;
	      }
	  }

	  .vr-light {
	    padding-bottom: 2em;
	  }


}
