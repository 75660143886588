:global {

	.block-lead {
	  display: block;
	  padding-top: 13em;
	  padding-right: 2em;
	  padding-left: 2em;
	  padding-bottom: 4em;
	  text-align: center;
	}

	/* heroes */

	.block {
	  display: block;
	  padding-top: 10em;
	  padding-bottom: 10em;
	  position: relative;
	  text-align: center;
	  overflow: hidden;
	}

	.block:hover::before {
	  transition-duration: 600ms;
	  transition-timing-function: ease-out;
	  transform: none;
	  opacity: 0.7;
	  -webkit-filter: grayscale(0);
	  filter: grayscale(0);
	}

	.block::before {
	  height: auto;
	  position: absolute;
	  top: 0;
	  z-index: 0;
	  opacity: .2;
	  background-repeat: no-repeat;
	  background-position: center;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;
	  -o-background-size: cover;
	  background-size: cover;
	  -webkit-filter: grayscale(100%);
	  filter: grayscale(100%);
	  -webkit-transform: translateZ(0);
	}

	.block-dark {
	  background-color: #23262b;
	  opacity: 1;
	}

	.block-light {
	  background-color: #393d42;
	  opacity: 1;
	}


	.block-dark::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  z-index: 1;
	  background-color: #23262b;
	  opacity: .5;
	}

	.block-light::after {
	  content: '';
	  position: absolute;
	  top: 0;
	  z-index: 1;
	  background-color: #393d42;
	  opacity: .5;
	}

	.work-title {
	  display: inline-block;
	  padding: 1em;
	  position: relative;
	  z-index: 2;
	  color: #fff;
	  cursor: pointer;
	}

	.block:hover .work-title::before {
	  visibility: visible;
	  -webkit-transform: scaleX(0);
	  transform: scaleX(0);
	}

	.work-title::before {
	  content: "";
	  position: absolute;
	  height: 1px;
	  bottom: .7em;
	  left: 1em;
	  right: 1em;
	  background-color: red;
	  visibility: visible;
	  -webkit-transform: scaleX(1);
	  transform: scaleX(1);
	  -webkit-transition: all 0.15s ease-out .1s;
	  transition: all 0.15s ease-out .1s;
	}

	.lindsay::before {
	  background-image: url('../Work/Lindsay/images/lindsay.jpg');
	  background-image: -webkit-image-set( url(../Work/Lindsay/images/lindsay.jpg) 1x, url(../Work/Lindsay/images/lindsay@2x.jpg) 2x);
	}

	.comfort-zone::before {
	  background-image: url('../Work/ComfortZone/images/comfortzone.jpg');
	  background-image: -webkit-image-set( url(../Work/ComfortZone/images/comfortzone.jpg) 1x, url(../Work/ComfortZone/images/comfortzone@2x.jpg) 2x);
	}

	.beercraft::before {
	  background-image: url('../Work/BeerCraft/images/beercraft.jpg');
	  background-image: -webkit-image-set( url(../Work/BeerCraft/images/beercraft.jpg) 1x, url(../Work/BeerCraft/images/beercraft@2x.jpg) 2x);
	}

	.ssi::before {
	  background-image: url('../Work/SSI/images/ssi.jpg');
	  background-image: -webkit-image-set( url(../Work/SSI/images/ssi.jpg) 1x, url(../Work/SSI/images/ssi@2x.jpg) 2x);
	  opacity: .1;
	}

	.cline::before {
	  background-image: url('../Work/Cline/images/cline.jpg');
	  background-image: -webkit-image-set( url(../Work/Cline/images/cline.jpg) 1x, url(../Work/Cline/images/cline@2x.jpg) 2x);
	}

	.tribe::before {
	  background-image: url('../Work/Tribe/images/tribe.jpg');
	  background-image: -webkit-image-set( url(../Work/Tribe/images/tribe.jpg) 1x, url(../Work/Tribe/images/tribe@2x.jpg) 2x);
	}

	.lightwell-survey::before {
	  background-image: url('../Work/LightwellSurvey/images/lightwellsurvey.jpg');
	  background-image: -webkit-image-set( url(../Work/LightwellSurvey/images/lightwellsurvey.jpg) 1x, url(../Work/LightwellSurvey/images/lightwellsurvey@2x.jpg) 2x);
	}

	.detente::before {
	  background-image: url('../Work/Detente/images/detente.jpg');
	  background-image: -webkit-image-set( url(../Work/Detente/images/detente.jpg) 1x, url(../Work/Detente/images/detente@2x.jpg) 2x);
	}

	.high-road-edibles::before {
	  background-image: url('../Work/HighRoadEdibles/images/highroadedibles.jpg');
	  background-image: -webkit-image-set( url(../Work/HighRoadEdibles/images/highroadedibles.jpg) 1x, url(../Work/HighRoadEdibles/images/highroadedibles@2x.jpg) 2x);
	}

}
