:global {

	.footer {
	  padding-bottom: 2em;
	  padding-left: 1em;
	  padding-right: 1em;
	}

	  @media (min-width: 48em) {
	    .footer {
	      padding-left: 2em;
	      padding-right: 2em;
	    }
	  }

	  @media (min-width: 64em) {
	      .footer {
	        padding-left: 2.65em;
	        padding-right: 2.65em;
	      }
	  }

	.bear {
	  display: inline-block;
	  width: 3em;
	  color: #000;
	  padding-top: 4em;
	  padding-bottom: 2em;
	}

	.dark-small {
	  font-size: .4em;
	  line-height: 1.85em;
	  color: #000;
	  padding-bottom: 2.5em;
	  text-transform: uppercase;
	}
	@media (min-width: 48em) {
	  .dark-small {
	    font-size: .8em;
	  }
	}

}
