:global {

	/* ----header----*/

	.header {
	  position: absolute;
	  width: 100%;
	  z-index: 1000;
	}

	@media (min-width: 48em) {
	  .header {
	    position: fixed;
	  }
	}

	.julian {
	  display: block;
	  float: left;
	  height: 100%;
	  width: 5em;
	  color: #fff;
	  padding-top: 1.8em;
	}

	  @media (min-width: 48em) {
	    .julian {
	      padding-top: 3.4em;
	      padding-left: 2em;
	    }
	  }

	  @media (min-width: 64em) {
	    .julian {
	      padding-top: 4.4em;
	      padding-left: 2.4em;
	    }
	  }

	/* ----footer----*/

	.footer {
	  padding-bottom: 2em;
	  padding-left: 1em;
	  padding-right: 1em;
	}

	  @media (min-width: 48em) {
	    .footer {
	      padding-left: 2em;
	      padding-right: 2em;
	    }
	  }

	  @media (min-width: 64em) {
	      .footer {
	        padding-left: 2.65em;
	        padding-right: 2.65em;
	      }
	  }

	.bear {
	  display: inline-block;
	  width: 3em;
	  color: #000;
	  padding-top: 4em;
	  padding-bottom: 2em;
	}

	.dark-small {
	  font-size: .4em;
	  line-height: 1.85em;
	  color: #000;
	  padding-bottom: 2.5em;
	  text-transform: uppercase;
	}
	@media (min-width: 48em) {
	  .dark-small {
	    font-size: .8em;
	  }
	}

	/* overlay (background) */

	.overlay {
	  transition-property: opacity;
	  transition-duration: 0.4s;
	  height: 100%;
	  width: 100%;
	  position: fixed;
	  z-index: 999;
	  top: 100%;
	  left: 0;
	  background-color: #16191e;
	  background-color: rgba(22,25,30, 0.95); /* gray w/opacity */
	  overflow-x: hidden;
	  overflow-y: scroll;
	  opacity: 0;
	  -webkit-overflow-scrolling: touch;
	}

	.s-overlay {
	  overflow: hidden;
	}

	.s-overlay .overlay {
	  top: 0;
	  opacity: 1;
	}

	ul.overlay li {
	  display: block;
	  line-height: 1em;
	}

	/*@media (min-width: 48em) {
	  ul.overlay li {
	    line-height: 1.25em;
	  }
	}*/

	ul.overlay li:last-child {
	  padding-bottom: 15em;
	}

	/* content inside overlay */

	.menu-links {
	  position: relative;
	  top: 15%;
	  width: 100%;
	  text-align: center;
	  -webkit-transition: all .3s;
	    transition: all .3s
	}

	@media (min-width: 48em) {
	  .menu-links {
	    top: 22%;
	  }
	}

	.nav-child {
	  opacity: .7;
	  text-decoration: none;
	}

	a.nav-child:before {
	  display: none;
	}

	a.nav-child:hover {
	  opacity: 1;
	  -webkit-transition: all .3s;
	    transition: all .3s
	}

	/* menu icon */

	.menu-icon {
	  position: relative;
	  padding-top: 1.5em;
	  display: inline-block;
	  float: right;
	  cursor: pointer;
	}

	  @media (min-width: 48em) {
	    .menu-icon {
	      padding-top: 3em;
	      padding-right: 2em;
	    }
	  }

	  @media (min-width: 64em) {
	      .menu-icon {
	        padding-top: 4em;
	        padding-right: 2.65em;
	      }
	  }

	.bar1, .bar2, .bar3 {
	  width: 25px;
	  height: 1px;
	  background-color: #fff;
	  margin: 7px 0;
	  transition: 0.2s;
	}

	.menu-icon:hover .bar1 {
	  transform: translateY(2px);
	  -webkit-transform: translateY(2px);
	  -moz-transform: translateY(2px);
	}

	.menu-icon:hover .bar3 {
	  transform: translateY(-2px);
	  -webkit-transform: translateY(-2px);
	  -moz-transform: translateY(-2px);
	}

	.s-overlay .menu-icon [class*="bar"] {
	   transform-origin: 100% 0;
	   -webkit-transform-origin: 100% 0;
	 }

	.s-overlay .menu-icon .bar1 {
	  transform: rotate(-45deg) translateY(2px) translateX(3px);
	  margin-right: .4em;
	}

	.s-overlay .menu-icon .bar2 {
	  opacity: 0;
	}

	.s-overlay .menu-icon .bar3 {
	  transform: rotate(45deg) translateY(-2px) translateX(3px);
	  margin-right: .4em;
	}

}
